/* Search Block
-------------------------------------------------- */
.business-search-container.loading {
    opacity: 0.8;
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    display: block;
}
.business-search-container .business-search-loading {
    position: absolute;
    width: 100%;
    top: 0;
    bottom:0;
    font-size: 40px;
    z-index: 100;
    display: none;
    background-color: rgba(0, 0, 0, 0.3);
}
.business-search-container .business-search-loading .tnt-svg {
    color: #fff;
}
.business-search-container .business-search-loading .spinner {
    top: 45%;
    position: absolute;
    width: 100%;
}
.business-search-container.loading .business-search-loading {
    display: block;
}
.business-search-container {
    background-color: rgba(0,0,0,.05);
    margin: 0 -15px;
    position: relative;
    margin-bottom: 15px;
}
.business-search-container .business-search-image {
    z-index: 0;
}
.business-search-container .business-search-image img {
    width: 100%;
}
.business-search-container .centered-content {
    position: relative;
    padding: 30px 15px;
    z-index:1;
    max-width: 90%;
}
.business-search-container .business-search-title h1 {
    margin:0;
    padding:0;
}
.business-search-container .lead {
    margin:0;
}
.business-search-container .business-search-sub-title {
    margin-top: 10px;
}
.business-search-container .business-search-form,
.business-search-container .business-search-block-links {
    margin-top: 20px;
}

.business-search-container .centered-content.shadow-text {
    text-shadow: 0 1px 3px rgba(0,0,0,.6);
}

.business-search-container .business-search-map-container {
    position: relative;
    max-height: 350px;
    overflow: hidden;
    webkit-transition: max-height 0.8s;
    -moz-transition: max-height 0.8s;
    transition: max-height 0.8s;
    border-top: 1px solid rgba(0,0,0,.1);
}

.business-search-container .business-search-map-container.closed {
    max-height: 0px;
}

.business-search-container .business-search-filters {
    background-color: #fff;
    border-top: 1px solid rgba(0,0,0,.1);
    border-bottom: 1px solid rgba(0,0,0,0.1);
    line-height: 30px;
    padding: 10px 0;
    z-index: 4;
}
.business-search-container .business-search-filters .form-group {
    margin-right: 10px;
    margin-bottom:0;
}
.business-search-container .business-search-filters .form-group label {
    font-size: 12px;
    margin-bottom: 0;
}
.business-search-container .business-search-filters .form-control {
    width: auto;
}
.business-search-container .business-search-filters.has-map .container {
    background-color: transparent!important;
}
.business-search-container span.twitter-typeahead {
    width: auto;
}

.business-search-container .business-map-toggle {
    cursor: pointer;
    opacity:.8;
    font-size: 20px;
}
.business-search-container .business-map-toggle:hover {
    opacity:1;
}
.business-search-container .tnt-chevron-right {
    opacity: .2;
}

.business-search-container .flags-checkbox {
    display: block!important;
    padding-left: 10px;
    cursor pointer;
    line-height: 24px;
    margin:0;
}
.business-search-container .flags-checkbox:hover {
    background-color: rgba(0,0,0,.05);
}
.business-search-container .flags-checkbox label { 
    display: block; 
}
.business-search-container .flags-dropdown-menu .dropdown-header {
    padding: 3px 10px;
    border-bottom: 1px solid rgba(0,0,0,.1);
    margin-bottom: 5px;
}
.business-search-container .flags-header { 
    margin-top: 5px;
}

@media screen and (min-width: 1280px) {
    .container-fullscreen-region .business-search-container .centered-content {
        max-width: 65%;
    }
}
@media screen and (max-width: 991px) {
    .business-search-container .centered-content {
        max-width: 100%;
    }
    .business-search-container .content-overlay {
        position: relative;
    }
    .business-search-container .business-search-image {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .business-search-container .business-search-filters {
        white-space: nowrap;
    }
    .business-search-container .business-search-filters .form-group {
        float: left;
    }
    .business-search-container .business-search-filters .btn {
        margin-top: 32px;
    }
    .business-search-container span.twitter-typeahead {
        display: block!important;
    }
    .business-search-container .business-search-title h1 {
        font-size: 150%;
    }
    .business-search-container .business-search-title h1 small {
        font-size: 85%;
    }
    .business-search-container .overflow-mobile {
        width: 100%;
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }
    .business-search-container .block-mobile {
        display: block;
        margin-top: 5px;
    }
    .business-search-container .flags-dropdown-menu {
        right:0;
        left: auto;
    }
}

/* Categories
-------------------------------------------------- */
.business-category-list-item {
    border-top: 1px solid rgba(0,0,0,.08);
    padding-bottom: 15px;
}

.business-category-list-item:first-child {
    border-top: 0;
}

.business-category-list-item h3 {
    margin-top: 15px;
    margin-bottom: 5px;
    padding: 0;
}

.business-category-list-item ul {
    margin-left: 24px;
}

.business-category-list-item ul .cat_depth_2 {
    margin-top: 10px;
    font-size: 18px;
}

.business-category-list-item ul .cat_depth_3 {
    margin-left: 18px;
    list-style: disc;
}

.business-category-list-item ul .cat_depth_4 {
    margin-left: 36px;
    list-style: circle;
}

/* Search Index
-------------------------------------------------- */
.business-search-title .block-title {
    margin: 15px 0 0;
}

.business-search-basic .card:first-child {
    border-top: 0;
}
.business-search-basic .mapped-number-badge {
    padding: 3px 7px;
    margin-top: -3px;
    font-size: 16px;
    font-weight: normal;
    border-radius: 12px;
}

/* Search Refine
-------------------------------------------------- */
.business-categories .category {
    margin-bottom: 5px;
}
.business-categories .category.active {
    font-weight: bold;
}
.business-categories .category.sub-cat {
    margin-left: 10px;
}
.business-refine-search-form .refine-list {
    margin-bottom: 0px;
}
.business-refine-search-form .refine-list li {
    width: 100%;
    float: left;
    min-height: 20px;
}
.business-refine-search-form .refine-list li .checkbox {
    margin-bottom: 2px;
    margin-top: 2px;
}
.business-refine-search-form .refine-checkboxes-show {
    margin-top: 5px;
}
.business-refine-search-form .form-group {
    margin-bottom: 20px;
}
.business-refine-search-form .refine-list li .tip {
    border-bottom: 1px dotted #777;
}
.business-refine-search-form input[type=number]::-webkit-outer-spin-button,
.business-refine-search-form input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.business-refine-search-form input[type=number] {
    -moz-appearance: textfield;
}
.business-refine-search-form .refine-range div {
    display: inline-block;
}
.business-refine-search-form .refine-range-input-container {
    width: 33%;
}
.business-refine-search-form .refine-range-text-container {
    width: 16%;
    text-align: center;
}
.business-refine-search-form .refine-range-btn-container {
    width: 18%;
    vertical-align: top;
    margin-left: -1px;
}
.business-refine-search-form.modal-form .form-group:last-child {
    margin-bottom: 0px;
}
.business-refine-search-form.modal-form .refine-range-input-container {
    width: 40%;
}
.business-refine-search-form.modal-form .refine-range-text-container {
    width: 8%;
    text-align: center;
}
.business-refine-search-form.modal-form .refine-range-btn-container {
    width: 12%;
    vertical-align: top;
    margin-left: -1px;
}
@media screen and (min-width:480px) {
    .business-refine-search-form.modal-form .refine-range-input-container {
        width: 42%;
    }
    .business-refine-search-form.modal-form .refine-range-text-container {
        width: 8%;
    }
    .business-refine-search-form.modal-form .refine-range-btn-container {
        width: 8%;
    }
    .business-refine-search-form.modal-form .refine-list li {
        width: 49%;
    }
}
.refine-modal .refine-content {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
}
.refine-modal .refine-content .form-group:last-child {
    margin-bottom: 0;
}
.app-business .search-whiteout {
    display: none;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    opacity: 0.65;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1998;
    overflow: hidden;
}
.app-business .search-spinner-container {
    display: none;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-left: -45px;
    margin-top: -35px;
    z-index: 1999;
}

/* Profile
-------------------------------------------------- */
.business-profile {
    padding-bottom: 40px;
}

.business-profile .block-title {
    margin-top: 30px;
}
@media (min-width: 992px) {
    .business-profile .business-about-columns .block-title {
        margin-top: 0;
    }
}

.business-header-default {
    position: relative;
}

.business-tabs-default {
    font-weight: bold;
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top: 1px solid rgba(0,0,0,.05);
    padding: 10px;
    clear: both;
    margin: 0 -15px 15px -15px;
    background-color: rgba(0,0,0,.02);
}

.business-tabs-default ul {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

.business-tabs-default .nav-pills>li {
    display: inline-block;
    float: none;
}

.business-cover {
    height: 250px;
    overflow: hidden;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 -15px;
    position: relative;
    background-image: url('../images/default-cover.69558a2164b5c76940c38dbfaaee913b.png');
}

.business-cover .business-cover-color {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .3;
}

.business-cover-fluid {
    background-size: cover;
}

.business-cover .streetview {
    width: 100%;
    height: 250px;
}

.asset-header h1 {
    margin: 20px 0;
}

.business-logo {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 20px -2px rgba(0,0,0,0.2);
    position: absolute;
    top: 24px;
    left: 10px;
    background-color: #fff;
    border: 20px solid #fff;
    width: 16%;
    max-width: 200px;
    z-index: 50;
}

.business-logo:before{
    content: "";
    display: block;
    padding-top: 100%;     /* maintain 1:1 aspect ratio */
}

h6.business-tagline.tagline-default {
    margin-top:-15px;
    margin-bottom: 16px;
}

@media screen and (max-width: 767px) {
    h6.business-tagline.tagline-default {
        margin-top: 5px;
        margin-bottom: 16px;
        font-size: 12px;
    }
}

h6.business-tagline.tagline-cover {
    position: absolute;
    top: 195px;
    font-size: 20px;
    right: 5px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, .8);
    z-index: 100;
}

.business-info-link {
    margin-bottom: 10px;
}

.business-info-link a,
.business-info-link a:hover {
    text-align: left;
    border-color: rgba(0,0,0,.2);
    font-size: 16px;
}

.business-info-link a .tnt-svg {
    margin-right: 8px;
}

table.business-hours {
    margin-bottom: 10px;
}

.business-hours td {
    padding: 3px 0;
}

.business-social-links {
    float: right;
}

.business-social,
.business-flags {
    margin-top: 20px;
}

.business-social li {
    padding: 0;
}

.business-social li a {
    transition: opacity .2s ease-in-out;
    -moz-transition: opacity .2s ease-in-out;
    -webkit-transition: opacity .2s ease-in-out;
    opacity: 0.8;
}

.business-social li a:hover {
    opacity: 1;
}

.business-social .fb .tnt-square {
    color: #3A59A5;
}

.business-social .tw .tnt-square {
    color: #46C8F9;
}

.business-social .fl .tnt-square {
    color: #FF6084;
}

.business-social .yt .tnt-square {
    color: #CE2B29;
}

.business-social .gp .tnt-square {
    color: #B83A3B;
}

.business-social .in .tnt-square {
    color: #0077b5;
}

.business-social .fs .tnt-square {
    color: #0732a2;
}

.business-social .ig .tnt-square {
    color: #125688;
}

.business-social .tr .tnt-square {
    color: #36465D;
}

.business-social .pt .tnt-square {
    color: #CE331A;
}

.business-social .tnt-stack-2x.tnt-square {
    background-color: transparent;
}
.business-testimonials blockquote {
    font-size: inherit;
    color: inherit;
    font-style: italic;
    padding:0;
}
.business-features li { 
    width: 49%; 
    min-height:40px;
    float:left;
}
.business-features li.pets,
.business-features li.f-txt-apt-pet-policy,
.business-features li.f-str-apt-term,
.business-features li.f-txt-apt-lease-info {
    width: 100%;
}
 #group-main-detail.business-features li {
    font-size: 16px;
 }
@media screen and (min-width: 768px) {
    .business-features li {
        width: 33%;
    }
    #group-main-detail.business-features li {
        width: 24%;
    }
    
}
#group-other-information dd {
    margin-bottom: 8px;
}
.niche-restaurants-price .price-icon {
    color: #aaa;
}
.niche-restaurants-price .price-icon-checked {
    color: #4f9650;
}

.business-hidden-event-here,
.business-hidden-event-related {
    display: none;
}

.business-events-here-btn,
.business-events-related-btn {
    margin-bottom: 20px;
}

.business-call-to-action {
    margin-bottom: 10px;
}

/* Mailing Lists & SMS Lists
-------------------------------------------------- */
.business-mailing-lists,
.business-sms-lists {
    position: relative;
}
.business-mailing-lists .business-signup-loading,
.business-sms-lists .business-signup-loading {
    display: none;
    position: absolute;
    z-index:100;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background-color: rgba(255,255,255,.7);
    font-size: 40px;
    padding-top: 50px;
}
.business-mailing-lists .business-mailing-list-title,
.business-sms-lists .business-sms-list-title {
    font-weight: bold;
}
.business-mailing-lists .business-mailing-list-description,
.business-sms-lists .business-sms-list-description {
    opacity: .8;
}
.business-mailing-lists .business-validation-errors,
.business-mailing-lists .business-system-errors,
.business-mailing-lists .business-signup-complete,
.business-sms-lists .business-validation-errors,
.business-sms-lists .business-system-errors,
.business-sms-lists .business-signup-complete {
    display: none;
}
.business-mailing-lists .alert h5,
.business-sms-lists .alert h5 {
    margin-top:0;
    font-weight: bold;
}
.business-mailing-lists .alert ul,
.business-sms-lists .alert ul {
    margin-left: -10px;
}

@media screen and (max-width: 767px) {
    .business-cover,
    .business-cover .streetview {
        height: 100px;
    }

    .business-social-links {
        float: none;
    }

    .business-logo {
        position: initial;
        border: 5px solid #fff;
        height: 90px;
        width: 90px;
        float: right;
        margin: 15px 0 15px 15px;
    }

    .business-profile .asset-header h1 {
        margin: 10px 0 0;
        font-size: 20px;
    }

    h6.business-tagline.tagline-cover,
    .business-tagline {
        position: static;
        font-size: 14px;
        color: inherit;
        text-shadow: none;
    }

    .business-payment-types li {
        margin-bottom: 5px;
    }
}